<footer class="mu-footer footer page-footer">
  <div class="container text-center">
    <!-- <div class="row">
      <div class="col">
        <img class="mt-3" src="../../../assets/unipolgruppo_tricolore.png" alt="Unipol logo">
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center justify-content-center">
        <small class="p-0 m-0">
          <a href="https://www.unipol.it/it/" target="blank">Unipol Gruppo S.P.A.</a>,
          Via Stalingrado 45 - 40128 Bologna | C.F. 00284160371 |
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center justify-content-center">
        <small class="p-0 m-0">
          P.IVA 03740811207 | Capitale Sociale I.V. € 3.365.292.408,03
          <a href="https://www.unipolsai.it/privacy" target="blank">Privacy Policy</a>
        </small>
      </div>
    </div> -->
    <div class="row row-margin">
      <div class="col">
        <a href="https://www.unipoltech.com/" target="_blank">
          <img class="mt-3 alfa-evolution-logo" src="../../../assets/U_Tech_bn_tratto_neg_OK.png" alt="UnipolTech">
        </a>
        <a href="https://www.unipol.it/homepage" target="_blank">
          <img class="mt-3 unipol-gruppo-logo" src="../../../assets/unipol_tratto_bn_neg.png" alt="Unipol">
        </a>
      </div>
    </div>
    <div class="mb-2">
      <div class="row">
        <div class="col">
          <div class="col d-flex align-items-center justify-content-center">
            <small class="p-0 m-0">
              UnipolTech S.p.A. 
              – Sede&nbsp;legale:&nbsp;via&nbsp;Stalingrado,&nbsp;37 
              - 40128&nbsp;Bologna&nbsp; 
              - <a href="mailto:unipoltech@pec.unipol.it">unipoltech@pec.unipol.it</a> 
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="col d-flex align-items-center justify-content-center">
            <small class="p-0 m-0">              
              tel.&nbsp;<a href="tel:+39 051 5077111">+39&nbsp;051&nbsp;5077111</a>
              - fax&nbsp;<a href="fax:+39 051 375349 ">+39&nbsp;051&nbsp;5077111</a>
              - Capitale&nbsp;sociale&nbsp;i.v.&nbsp;Euro&nbsp;5.000.000,00 
              – Registro&nbsp;delle&nbsp;Imprese&nbsp;di&nbsp;Bologna, C.F.&nbsp;03506831209&nbsp;P.IVA&nbsp;03740811207
              - R.E.A 524585
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="col d-flex align-items-center justify-content-center">
            <small class="p-0 m-0">              
              Societ&agrave; unipersonale soggetta all'attivit&agrave; di direzione e coordinamento di Unipol S.p.A. e facente parte del Gruppo Assicurativo Unipol iscritto all'Albo delle societ&agrave; capogruppo al n.046
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="col d-flex align-items-center justify-content-center">
            <small class="p-0 m-0">              
              <a href="https://www.unipoltech.com" target="_blank">unipoltech.com</a>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>